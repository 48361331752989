$light-gray: #E5E5E5;
$dark-gray: #B2B1B1;
$grey-title: #454444;
$light-pink: #FCE8E8;



.projects{
    #portofolio {
        margin-bottom: 50px;
    }
    
    .project-container{
        width: 100%;
        display: flex;
        flex-direction: column; 
    }
    
    .project{
        // padding: 0px 100px;
        a{
            text-decoration: none;
            color: grey;
        }
        span{
            font-size: 15px;
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            padding: 10px 300px;
            // background: linear-gradient(0.25turn, white, lightgrey, white);
            background: lightgrey;
            color: black;
            &:hover{
                background: darkgrey;
                color: white;
            }
        }
        margin-bottom: 50px;

       
        width: 100%;
       
        background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
        &:hover{
            // background: white;
        }
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        
        .project-title{
            padding-top: 20px;
            display: flex;
            width: 100%;
          
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            border-bottom: 2px solid black;
      
            text-align: left;
   
        }
        
        h1{
            font-size: 20px;
    
            text-align: left;
            font-weight: bold;
            color: #2d2d2d;
    
        }
        h2{
            font-size: 15px;
            text-align: center;
            width: 100%;
            letter-spacing: 2px;
            
            
        }
        p{
            color: grey;
            font-size: 15px;
        }
        .summary{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            border-top: 1px solid black;
            margin-top: 20px;
            h2{
                margin-top: 20px;
            }
            
            @media (min-width: 500px){
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                border-top: 1px solid black;
                margin-top: 20px;
                h2{
                    margin-top: 20px;
                }
            }
            
        }
        .main-skills {
            h2{
                margin-top: 10px;
                font-size: 20px;
                font-weight: bold;
            }
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            @media (min-width: 500px){
                h2{
                    margin-right: 20px;
                    font-size: 25px;
                    font-weight: bold;
                }
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                // flex-wrap: wrap;
                height: 50px;
            }
         
        }
        .extra-skills {
            h2{
                margin-top: 10px;
                font-size: 15px;
                color: grey;
                
            }
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            @media (min-width: 500px){
                h2{
                    margin-right: 20px;
                    font-size: 20px;
                    color: grey;
                    
                }
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                // flex-wrap: wrap;
                height: 50px;
            }
          

        }
        
    }

    .images-container{
        width: 320px;
        height: 300px;
        overflow: scroll;
        display: flex;
        justify-content: flex-start;
        align-items: center;
     
    
    }
    .image-container{
        width: 100%;
        height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        

        h3{
           
        }
    }
    span{
        font-size: 25px;
    }
    img {
        width: 320px;
        height: auto;
        
    }
    
    h1{
     
    }
    
    p{ 
        font-size: 25px;
        // background: linear-gradient(0.25turn, $light-pink,white, white, white, white, $light-pink);
        // width: 100%;
        line-height: 30px;
        
        text-align: center;
        // padding: 15px 150px;
        // margin-bottom: 30px;
        span{
            font-weight: bold;
            font-size: 30px;
        }
    }
    
    p{ 
        // font-size: 20px;
        // background: linear-gradient(0.25turn, $light-pink,white, white, white, white, $light-pink);
        // width: 100%;
        // line-height: 30px;
        // text-align: center;
        // // padding: 15px 150px;
        // // margin-bottom: 30px;
        // span{                          
        //     font-weight: bold;
        //     font-size: 20px;
        // }
    }

    @media (min-width: 500px){

        .project-container{
            // overflow: scroll;
            // height: 800px;
            width: 100%;
            display: flex;
            flex-direction: column;
            // border: 1px solid red;
            margin: 10px 10px;
            
        }
        nav {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                font-size: 30px;
                background: grey;
                color: white;
                border: 1px solid white;
                padding: 20px 20px;
            }
        }
        .project{
            a{
                text-decoration: none;
                color: grey;
            }
            span{
                font-size: 25px;
                border-top: 2px solid black;
                border-bottom: 2px solid black;
                padding: 10px 300px;
                // background: linear-gradient(0.25turn, white, lightgrey, white);
                background: lightgrey;
                color: black;
                &:hover{
                    background: darkgrey;
                    color: white;
                }

            }
            // border: 2px solid grey;
            // height: 700px;
            width: 100%;
            // margin-left: 20px;
            // border-radius: 10%;
            // background: white;
            background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 10px 90px;
            padding-bottom: 50px;
            // text-align: left;
            margin-bottom: 50px;
            
            
            
            .project-title{
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 2px solid black;
                width: 100%;
                padding: 10px 10px;
                text-align: left;
                margin-bottom: 20px;
            }
            
            h1{
                font-size: 30px;
                // width: 100px;
                text-align: left;
                font-weight: bold;
                color: black;
                // margin-bottom: 20px;
            }
            h2{
                font-size: 25px;
                text-align: center;
                width: 100%;
                letter-spacing: 2px;
                
                
            }
            p{
                color: grey;
                font-size: 20px;
            }
            
        }

        .images-container{
            width: 1200px;
            height: 900px;
            overflow: scroll;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 10px;
            margin-bottom: 25px;
            
            
            // margin-top: 30px;
        
        }
        .image-container{
            width: 100%;
            height: 800px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            margin: 10px 10px;
            h3{
                margin-top: 25px;
                font-size: 25px;
            }
        }
        span{
            font-size: 25px;
        }
        img {
            width: 1000px;
            height: auto;
            
        }
        // img:hover{
            
        //     width: 1000px;
        //     height: auto;
            

        // }
        h1{
            font-size: 30px;
            // margin-bottom: 20px;
        }
        
        p{ 
            font-size: 25px;
            // background: linear-gradient(0.25turn, $light-pink,white, white, white, white, $light-pink);
            // width: 100%;
            line-height: 50px;
            text-align: center;
            // padding: 15px 150px;
            // margin-bottom: 30px;
            span{
                font-weight: bold;
                font-size: 30px;
            }
        }
        
    }
    
}