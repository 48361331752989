.contact{
    .link{
        margin-top: 10px;
        background: linear-gradient(0.25turn, lightgrey, white, white, white, lightgrey);
        &:hover{
            background: white;
            color: black;
        }
        padding: 0px 5px;
        margin-bottom: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 270px;
        width: 100%;
        
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-between;

        a {
            height: 25px;
            // margin-top: 10px;
            padding: 20px 0px;
            // border: 2px solid grey;
            h2{
                &:hover{
                   color: black;
                   align-self: center;
                   
                }
            }
            text-decoration: none;
        }
    }
    .email {
        background: linear-gradient(0.25turn, lightgrey, white, white, white, lightgrey);
        &:hover{
            background: white;
            
        }
        padding: 20px 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 0px;
        // width: %;
        i{
            font-size: 30px;
            margin-right: 5px;
        }
    }
    .links{
        border-top:2px solid grey;
        display:flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        @media (min-width: 500px){
            flex-direction: row;
        }
        
        .img-container{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            margin-top: 15px;
            // width: 200px;
            // height: auto;
            img{
                width: 80px;
                height: auto;
            }
        }
    }
    .find-me-online{
    // border: 2px solid grey;
    // border-radius: 10%;
    // background: white;
    // background: linear-gradient(0.25turn, lightgrey, white, white, white, lightgrey);
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 0px 15px;
    text-align: center;
    // margin-bottom: 200px;
    h1{
        font-size: 20px;
        width: 100%;
        text-align: center;
        // font-weight: bold;
        margin-bottom: 0px;
        color: black;
    }
    h2{
        font-size: 15px;
        text-align: center;
        width:100%;
        // color: rgb(219, 211, 211);
        color: black;
        
    }
    p{
        // color: black;
        font-size: 15px;
        width: 100%;
        text-align: center;
    }
    }
    
    @media (min-width: 500px){
        .link{
            margin-top: 60px;
            margin-bottom: 100px;
            height: 300px;
            width: 300px;
            
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-between;
            a {
                text-decoration: none;
            }
        }
        .email {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            // width: %;
            i{
                font-size: 30px;
                margin-right: 20px;
            }
        }
        .links{
            display:flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            
            .img-container{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100px;
                // width: 200px;
                // height: auto;
                img{
                    width: 150px;
                    height: auto;
                }
            }
        }
        .find-me-online{
        // border: 2px solid grey;
        // border-radius: 10%;
        // background: white;
        // background: linear-gradient(0.25turn, lightgrey, white, white, white, lightgrey);
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        padding: 0px 150px;
        text-align: center;
        // margin-bottom: 200px;
        h1{
            font-size: 25px;
            width: 100%;
            text-align: center;
            // font-weight: bold;
            margin-bottom: 0px;
            color: black;
            
        }
        h2{
            font-size: 20px;
            text-align: center;
            width:100%;
            color: grey;
            
        }
        p{
            // color: black;
            font-size: 20px;
            width: 100%;
            text-align: center;
        }
    }
        
    
    
    }
}