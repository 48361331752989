$light-gray: #E5E5E5;
$dark-gray: #B2B1B1;
$grey-title: #454444;
$light-pink: #FCE8E8;




.header-container {
    background: $dark-gray;
    position: fixed;
    width: 100%;
    top: 0px;
    
    
   
    .button-for-mobile{
        position: fixed;
        //to be on the side
        // right: 20px;
        // top: 270px;

        //tobe on the top
        // right: 160px;
        top: 65px;
        width: 100%;
        z-index: 1;
        
        border-top: 1px solid white;
        color: white;
       
        
        
        i {
            font-size: 30px;
            width: 100%;
            background:#B2B1B1;
            color: white;
            border-radius: 5%;
            // border: 1px solid black;
            // padding-left: 5px;
            // padding-right: 10px;
        }
        &:hover{
            color: black;
        }
        @media (min-width: 500px){
            display: none;

        }
    }
    .no-mobile-nav{
        display: none;
    }
    .mobile-nav{
        display: inline-flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: center;
        height: 400px;
        // margin: 0 auto;
        position: absolute;
        right: 0px;
        top: 95px;
        // border-radius: 5%;
        // align-self: center;
        width: 100%;
        border: 1px solid black;
        // background: grey;
        background: rgba(100, 100, 100, 0.9);
        text-align: center;
        animation: navSlide 1.5s ease 0.1s 1 backwards;
        @media (min-width: 500px){
            display: none;

        }

        .linkHovered {
            text-decoration: none;
            span{
                text-decoration: none;
                color: white;
                padding: 15px 75px;
                &:hover{
                    // color: black; 
                    // background: linear-gradient(0.25turn, grey,$light-pink,$light-pink, $light-pink, grey) ;
                }
            }
        }
        
        }
       

        @keyframes navSlide {
        0% {
            height: 10px;
            width: 100%;
            transform: translate(0px, 0px, 0px, 0px);
        }
        // 50% {
        //   height: 400px;
        //   width: auto;
        //   transform: translate(0px, 0px);
        // }
        100% {
            height: 400px;
            width: 100%;
            transform: translate(0px, 0px, 0px, 0px);
        }
        

        
        
        @media (min-width: 500px){
            display: none;

        }
        .link, .linkHovered {
            text-decoration: none;
            color: white;
        }
        span{
            display: block;
            text-decoration: none;
        }
    }
    
    
}
header {
    
   

    background: $dark-gray;
    
    height: 100%;
    
    color: white;
    margin-bottom: 30px;

    .name-title-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px 0px;
        width: 100%;
        @media (min-width: 500px){
            display: flex;
            justify-content: space-around;
            align-items: center;
            // border: 1px solid black;
            padding: 40px 0px;
        }  
        h1 {
            font-size: 20px;
            // text-shadow: 2px 1px 1px grey;
            // font-weight: bold;
            
            // background: linear-gradient(0.25turn, $dark-gray,$light-pink,$light-pink, $light-pink, $dark-gray) ;
            // background: linear-gradient(0.25turn, white,$dark-gray, white, $dark-gray);
            width: 100%;
            // background: white;
            
            // width: 375px;
            // text-align: center;
            
            
            margin-bottom: 5px;
            padding: 5px 5px;
            // color: $grey-title;
            color:rgb(38, 38, 38);
            font-weight: medium;
            // color:white;
            // font-weight: bold;
            @media (min-width: 500px){
                background: linear-gradient(0.25turn, lightgrey, white, white, lightgrey);
                // font-weight: bold;
                width: 100%;
                font-size: 45px;
                margin-bottom: 25px;
                letter-spacing: 4px;
                padding: 20px 210px;
                text-shadow: 1px 1px 1px white;
                &:hover{
                    background: white;
                    color:black;

                }
                // font-weight: bold;
                
            }
            // &:hover {
            //     background: linear-gradient(0.25turn, $dark-gray,$light-pink,$light-pink, $light-pink, $dark-gray) ;
            //     padding: 20px 20px;
            // }
            
        }
        h2 {
            font-size: 15px;
            letter-spacing: 2px;
            
            @media (min-width: 500px){
                font-size: 22px;
                letter-spacing: 15px;
                text-shadow: 1px 1px 1px grey;
            }
            // &:hover{
            //     color: black;
            // }
        }
    }
    
    nav{
        display: none;
        height: 42px;
        @media (min-width: 500px){
            display: inline-flex;
            // flex-direction: row;
            
            // justify-content: space-around;
            // align-content: center;
            border-top: 1px solid black;
            // border: 1px solid black;
            // padding: 10px 0px;
        }
        .linkHovered {
            text-decoration: none;
            color: rgb(231, 231, 231);
            span{
                background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
                // background: $light-pink;
                color: black;
                font-size: 20px;
            }
        } 
        span{
            display: block;
            width: 250px;
            text-decoration: none;
            // border: 1px solid red;
            padding: 10px 70px;
            // font-size: 20px;

            &:hover{
                background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
                color: black;
                font-size: 20px;
            }
            &:active{
                background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
                color: black;
            }
            
        }
        
    }
    
}
