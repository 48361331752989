
$light-gray: #E5E5E5;
$dark-gray: #B2B1B1;
$grey-title: #454444;
$light-pink: #FCE8E8;


.main-container{
    // background: #B2B1B1;
    background: lightgrey;
    
    .name-title-container{
        margin-top: 100px;
        
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px 0px;
        @media (min-width: 500px){
            margin-top: 200px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            // border: 1px solid black;
            padding: 40px 0px;
            
        }  
        h1 {
            margin-top: 100px;
            font-size: 30px;
            text-shadow: 1px 1px 1px white;
            // font-weight: bold;
            // background: linear-gradient(0.25turn, $dark-gray,$light-pink,$light-pink, $light-pink, $dark-gray) ;
            // background: linear-gradient(0.25turn, $dark-gray, white, white, $dark-gray);
            // background: white;
            background: linear-gradient(0.25turn, lightgrey, white, white, lightgrey);
            margin-top: 100px;
            
            
            margin-bottom: 20px;
            padding: 15px 20px;
            // color: $grey-title;
            color:rgb(63, 63, 63);

            -webkit-animation-name: mobile-hello; /* Safari 4.0 - 8.0 */
            -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
            animation-name: mobile-hello;
            animation-duration: 3s;
            
            
            animation: mobile-hello duration timing-function delay iteration-count direction fill-mode;
            @keyframes mobile-hello {
                0%   {
                    // opacity: 0;
                    font-size: 15px;
                    
                }
                100% {
                    // opacity: 1;
                    font-size: 30px;
                    
                }
            }



            @media (min-width: 500px){
                text-shadow: 1px 1px 1px grey;
                background: linear-gradient(0.25turn, lightgrey, white, white, lightgrey);
                color: #262626;
                width: 100%;
                font-size: 45px;
                margin-bottom: 25px;
                letter-spacing: 4px;
                padding: 20px 250px;
                text-shadow: 3px 1px 1px white;
                &:hover{
                    background: white;
                    color: black;
                }

                -webkit-animation-name: hello; /* Safari 4.0 - 8.0 */
                -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
                animation-name: hello;
                animation-duration: 3s;
                
                
                animation: hello duration timing-function delay iteration-count direction fill-mode;
                @keyframes hello {
                    0%   {opacity: 0;
                    font-size: 15px;}
                    100% {opacity: 1;}
                }
                
            }
            // &:hover {
            //     background: linear-gradient(0.25turn, $dark-gray,$light-pink,$light-pink, $light-pink, $dark-gray) ;
            //     padding: 20px 20px;
            // }
            
        }
        h2 {
            font-size: 15px;
            letter-spacing: 2px;
            color: rgb(82, 81, 81);
            text-shadow: 1px 1px 1px white;
            -webkit-animation-name: knowme-mobile; /* Safari 4.0 - 8.0 */
                -webkit-animation-duration: 5s; /* Safari 4.0 - 8.0 */
                animation-name: knowme-mobile;
                animation-duration: 5s;
                
                
                @keyframes knowme-mobile {
                    0%   {opacity: 0;
                        color: lightgrey;}
                            // transform: scaleY(4)
                        
                        // transform: translate(0%, 50%);}
                        // 25%  {opacity: 0.3;}
                        // 50%  {opacity: 0.5;}
                        100% {opacity: 1;
                        }
                }
            @media (min-width: 500px){
                color: black;
                font-size: 25px;
                letter-spacing: 15px;
                text-shadow: 1px 1px 1px white;
                -webkit-animation-name: knowme; /* Safari 4.0 - 8.0 */
                -webkit-animation-duration: 5s; /* Safari 4.0 - 8.0 */
                animation-name: knowme;
                animation-duration: 5s;
                
               
                @keyframes knowme {
                    0%   {opacity: 0;
                    color: lightgrey;}
                        // transform: scaleY(4)
                    
                    // transform: translate(0%, 50%);}
                    // 25%  {opacity: 0.3;}
                    // 50%  {opacity: 0.5;}
                    100% {opacity: 1;
                    }
                }
            }
            // &:hover{
            //     color: black;
            // }
        }
    }
    
    color: white;
    main{
        background: lightgrey;
        display: flex;
        align-items: center;
        justify-content: FLEX-START;
        flex-direction: column;
        // margin-top: 250px;
        h1{
            font-size: 60px;
            color: white;
            // margin-top: 50px;
        }
    }
    nav{
        display: none;
        height: 42px;
        @media (min-width: 500px){
            display: inline-flex;
            // flex-direction: row;
            
            // justify-content: space-around;
            // align-content: center;
            border-top: 1px solid black;
            // border: 1px solid black;
            // padding: 10px 0px;
        }
        .linkHovered {
            text-decoration: none;
            color: rgb(231, 231, 231);
            span{
                background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
                // background: $light-pink;
                color: black;
                font-size: 20px;
            }
        } 
        span{
            display: block;
            width: 250px;
            text-decoration: none;
            // border: 1px solid red;
            padding: 10px 70px;
            // font-size: 20px;

            &:hover{
                background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
                color: black;
                font-size: 20px;
            }
            &:active{
                background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
                color: black;
            }
            
        }
        
    }
}