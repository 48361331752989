$light-gray: #E5E5E5;
$dark-gray: #B2B1B1;
$grey-title: #454444;
$light-pink: #FCE8E8;


.App {
  font-family: 'Raleway', sans-serif;
  // font-family: 'Oswald', sans-serif;
  // font-family: 'Roboto', sans-serif;
 
  text-align: center;
  @media (min-width: 500px){
    font-family: 'Sinhala MN', sans-serif;
    width: 100vw;
    height: 100vh;
  }
  // max-width: 320px;
  // width: 100vw;
  // height: 100vh;
}


main, header, footer, .about, .projects, .education, .experience {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  @media (min-width: 500px){
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    
    
  }
}

.about, .projects, .education, .contact, .experience, main {
  width: 100%;
  max-width: 320px;
  height: 677px;
  overflow: scroll;
  margin: 0 auto;
  // padding-top: 50px;
  // margin-top: 150px;
  padding: 0px 10px;
  @media (min-width: 500px){
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 1200px;
    padding-top: 50px;
  }
}

// .projects{
//   overflow
// }

.header-container{
  width: 100%;
}
.about-container, .projects-container, .contact-container, .education-container, .experience-container{
  background:lightgrey;
  padding-top: 120px;
  @media (min-width: 500px){
    width: 100%;
    margin-top: 120px;
    
  }
}
.title{
  // background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
  // background: white;
  height: 60px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: CENTER;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (min-width: 500px){
    width: 100%;
    margin-top: 0px;

    h1{
      font-size: 25px;
      // margin: 20px 0px;
      text-shadow: 3px 1px 1px white;
      padding-right: 10px;
      // background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
        // color: white;
    }
    
  }

  
    
  
}

.link {
  text-decoration: none;
  color: rgb(231, 231, 231);
}




