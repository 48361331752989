$light-gray: #E5E5E5;
$dark-gray: #B2B1B1;
$grey-title: #454444;
$light-pink: #FCE8E8;

.education{
    
    .position{
        button{
            font-family: ' sans-serif';
            
            a{
                text-decoration: none;
                color: grey;
                &:hover{
                    color: black;
                }
            }
        }
        // border: 2px solid grey;
        background: linear-gradient(0.25turn, lightgrey, white, white, white, lightgrey);
        &:hover{
            background: white;
        }
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        padding: 10px 10px;
        margin-bottom: 20px;
        h1{
            font-size: 20px;
            width: 100%;
            text-align: center;
            font-weight: bold;
            
            color: #2d2d2d;
            margin-bottom: 10px;
        }
        h2{
            font-size: 20px;
            text-align: center;
            width: 360px;
            margin-bottom: 10px;
            
        }
        p{
            color: grey;
            font-size: 15px;
            margin-bottom: 10px;
        }
        
    }
    
    
    // p{ 
    //     font-size: 20px;
    //     // background: linear-gradient(0.25turn, $light-pink,white, white, white, white, $light-pink);
    //     // width: 100%;
    //     line-height: 50px;
    //     text-align: center;
    //     // padding: 15px 150px;
    //     // margin-bottom: 30px;
    //     span{
    //         font-weight: bold;
    //         font-size: 30px;
    //     }
    // }

    @media (min-width: 500px){
        .position{
            button{
                font-family: 'Sinhala MN','Open Sans', sans-serif;
                cursor: pointer;
                font-size: 15px;
                a{
                    text-decoration: none;
                    color: grey;
                    &:hover{
                        color: black;
                    }
                }
                // border: 1px solid white;

            }
            // border: 2px solid grey;
            // border-radius: 10%;
            // background: white;
            background: linear-gradient(0.25turn, lightgrey, white, white, white, lightgrey);
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            padding: 30px 150px;
            text-align: center;
            margin-bottom: 40px;
            h1{
                font-size: 25px;
                width: 100%;
                text-align: center;
                font-weight: bold;
                color: black;
                // margin-bottom: 20px;
            }
            h2{
                font-size: 20px;
                text-align: center;
                width:100%;
                
            }
            p{
                color: grey;
                font-size: 20px;
                width: 100%;
                text-align: center;
            }
            
        }
        h1{
            font-size: 30px;
            // margin-bottom: 20px;
        }
        
        p{ 
            font-size: 25px;
            // background: linear-gradient(0.25turn, $light-pink,white, white, white, white, $light-pink);
            // width: 100%;
            line-height: 50px;
            text-align: center;
            // padding: 15px 150px;
            // margin-bottom: 30px;
            span{
                font-weight: bold;
                font-size: 30px;
            }
        }
        
    }
    
}