$light-gray: #E5E5E5;
$dark-gray: #B2B1B1;
$grey-title: #454444;
$light-pink: #FCE8E8;

.experience{
    
    .position{
        background: linear-gradient(0.25turn, lightgrey, white, white, white, lightgrey);
        &:hover{
            background: white;
        }
        // background: white;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        padding: 10px 10px;
        text-align: center;
        margin-bottom: 20px;
        h1{
            font-size: 20px;
            color: #2d2d2d;
            width: 100%;
            text-align: left;
            font-weight: bold;
            margin-bottom: 5px;
        }
        h2{
            font-size: 20px;
            text-align: left;
            width: 100%;
            margin-bottom: 5px;
            
        }
        p{
            color: grey;
            font-size: 15px;
            text-align: right;
            width:100%;
            
        }
        
    }
    
    
    

    @media (min-width: 500px){
        .position{
            // border: 2px solid grey;
            // border-radius: 10%;
            background: linear-gradient(0.25turn, lightgrey, white, white, white, lightgrey);
            display: inline-flex;
            flex-direction: row;
            width: 1000px;
            justify-content: space-between;
            align-items: center;
            padding: 20px 25px;
            text-align: left;
            margin-bottom: 20px;
            h1{
                font-size: 25px;
                width: 100%;
                text-align: left;
                font-weight: bold;
                
                color: black;
                // margin-bottom: 20px;
            }
            h2{
                font-size: 20px;
                text-align: left;
                width: 100%;
                
            }
            p{
                color: grey;
                font-size: 20px;
            }
            
        }
      
        
      
        
    }
    
}