$light-gray: #E5E5E5;
$dark-gray: #B2B1B1;
$grey-title: #454444;
$light-pink: #FCE8E8;


.footer-container{
    background:  #B2B1B1;
    height: 50px;
    // border: 2px solid white;
    // position: fixed;
    width: 100%;
    bottom: 0px;
    // margin-top: 200px;
}