$light-gray: #E5E5E5;
$dark-gray: #B2B1B1;
$grey-title: #454444;
$light-pink: #FCE8E8;

.about{
    img {
        width: auto;
        height: 400px;
        border: 4px solid white;
        margin-bottom: 25px;
        margin-top: 25px;
        // border-radius: 20%;
        // margin: 20px 0px;
    }

    .p-image{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        @media (min-width: 500px){
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
        }


        
    }
    .title {
        margin-top: 20px;
    }
    
    
    p{ 
        font-size: 20px;
        background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
        width: 100%;
        line-height: 30px;
        text-align: center;
        // padding: 15px 150px;
        // margin-bottom: 30px;
        span{
            font-weight: bold;
            font-size: 20px;
        }
    }

    @media (min-width: 500px){
        img {
            width: auto;
            height: 600px;
            border: 4px solid white;
            margin-bottom: 25px;
            margin-top: 25px;
            // border-radius: 20%;
            // margin: 20px 0px;
        }
        
        h1{
            font-size: 40px;
            margin-bottom: 20px;
            
        }
        
        p{ 
            font-size: 25px;
            // background: linear-gradient(0.25turn, $light-pink,white, white, white, white, $light-pink);
            background: linear-gradient(0.25turn, lightgrey,white, white, white, white, lightgrey);
            width: 100%;
            line-height: 50px;
            text-align: center;
            padding: 60px 20px;
            margin-bottom: 30px;
            span{
                font-weight: bold;
                font-size: 30px;
            }
        }
        
    }
    
}